<template>
  <div class="wrap">
    <!-- <van-nav-bar title="检验单查询" left-arrow @click-left="onClickLeft" /> -->
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold">
        检验单查询
      </span>
    </div>
    <van-cell-group>
      <van-field
        v-model="cardNo"
        label="就诊卡号"
        placeholder="请输入患者就诊卡号"
      />
    </van-cell-group>
    <div class="btn" @click="goInfo">查询</div>
  </div>
</template>
<script>
import { Toast } from "vant";
import { setStore, getStore, removeStore } from "@/utils/localStorage.js";
export default {
  data() {
    return {
      cardNo: "",
      backIcon: localStorage.getItem("routerHistory"),
    };
  },
  methods: {
    onClickLeft() {
      this.$router.push({ path: "/menu" });
    },
    goInfo() {
          if (getStore({ name: "reportstart" })) {
      removeStore({ name: "reportstart" });
    }
    if (getStore({ name: "reportend" })) {
      removeStore({ name: "reportend" });
    }
      this.cardNo = this.cardNo.trim();
      if (this.cardNo) {
        this.$router.push({
          path: "/inspectionQueryList",
          query: {
            cardNo: this.cardNo,
          },
        });
      } else {
        Toast.success({ type: "error", message: "就诊卡号不能为空" });
        return;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 20px;
  .btn {
    width: 90%;
    height: 40px;
    margin: 0 auto;
    background: #1578f9;
    font-size: 18px;
    border-radius: 23px 23px 23px 23px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
  }
  .nav_bar {
    text-align: center;
    color: #323233;
  }
}

/deep/.van-cell-group {
  margin: 16px;
  border-radius: 10px;
}

/deep/.van-cell {
  border-radius: 10px;
}
</style>
